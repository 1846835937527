import {
  StyledBox,
  StyledButton,
  StyledH1,
  StyledH2, StyledImg
} from "./ArchiveGameWorks";
import React, {useState, useEffect} from 'react';
import {useRimSky} from "../StateManagment/RimSkyState";
import {Unity, useUnityContext} from 'react-unity-webgl';
import Typography from "@mui/material/Typography";


function WebGames() {
  const [ windowDimensions, setWindowDimensions ] = useState({
    width: Math.min(window.innerWidth, 1080),
    height: Math.min(window.innerHeight, 1080)
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({width: Math.min(window.innerWidth, 1080), height: Math.min(window.innerHeight, 1080)});
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  function App() {
    const {unityProvider, unload} = useUnityContext({
      loaderUrl: 'Build/BlackJackWebGL.loader.js',
      dataUrl: 'Build/BlackJackWebGL.data.unityweb',
      frameworkUrl: 'Build/BlackJackWebGL.framework.js.unityweb',
      codeUrl: 'Build/BlackJackWebGL.wasm.unityweb',
    });

    async function handleClickBack() {
      await unload();
      // Ready to navigate to another page.
    }

    return (
      <div style={ {display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'} }>
        <Unity unityProvider={ unityProvider }
               style={ {width: windowDimensions.width, height: windowDimensions.height} }/>
      </div>
    )
      ;
  }

  const [ isGameVisible, setGameVisible ] = useState(false);
  const {rimSkyImage} = useRimSky();
  const [ rimCurrentPic, setRimCurrentPic ] = useState(0);
  return (
    <div>
      <StyledBox>
        <StyledH1>WebGames I Have Created</StyledH1>
        <br/>
        <br/>
        <StyledH1 data-testid="RimSky Header" style={ {textAlign: "center"} }>
          RimSky
        </StyledH1>
        <br/>
        <StyledButton
          sx={ {fontSize: "50px"} }
          href="https://simmer.io/@Kainthekiller/rimsky-final"
          //@ts-ignore
          target="_blank"
        >
          Click to Play
        </StyledButton>

        <li>AI</li>
        <li>Animations</li>
        <li>Sound Design</li>
        <li>Level Design</li>
        <li>Boss Fights</li>
        <li>Sword & Shield Fighting</li>
        <li>Archer Enemies & Sword Enemies</li>
        <StyledImg
          src={ rimSkyImage[rimCurrentPic] }
          alt={ "RimSky Image" }
        ></StyledImg>
        <StyledButton
          onClick={ () => {
            if (rimCurrentPic > 0) {
              setRimCurrentPic(rimCurrentPic - 1);
            }
          } }
        >
          Back
        </StyledButton>
        <StyledButton
          onClick={ () => {
            if (rimCurrentPic !== rimSkyImage.length - 1) {
              setRimCurrentPic(rimCurrentPic + 1);
            }
          } }
        >
          Next
        </StyledButton>
      </StyledBox>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <StyledBox>
        <StyledH1>Black Jack</StyledH1>
        <li>Unity</li>
        <li>Graphic Art</li>
        <li>Game Design</li>
        <li>WebGl</li>
        <StyledH2>Simple BlackJack Game I made in unity useing WebGL</StyledH2>
      <StyledButton  sx={ {fontSize: "50px"} } onClick={ () => setGameVisible(true) }>Click To Play</StyledButton>
      { isGameVisible && <App/> }
      </StyledBox>
    </div>
  );
}

export default WebGames;
