import React, { createContext, useContext, useState } from "react";
import rimsky1 from "../assets/Pic/Rimsky/rimsky1.png";
import rimsky2 from "../assets/Pic/Rimsky/rimsky2.png";
import rimsky3 from "../assets/Pic/Rimsky/rimsky3.png";
import rimsky4 from "../assets/Pic/Rimsky/rimsky4.png";
import rimsky5 from "../assets/Pic/Rimsky/rimsky5.png";
import rimsky6 from "../assets/Pic/Rimsky/rimsky6.png";
import rimsky7 from "../assets/Pic/Rimsky/rimsky7.png";
import rimsky8 from "../assets/Pic/Rimsky/rimsky8.png";

//CPU

interface RimSkyContextInterface {
  rimSkyImage: string[];
}

const RimSkyContext = createContext<RimSkyContextInterface>({
  rimSkyImage: [
    rimsky1,
    rimsky2,
    rimsky3,
    rimsky4,
    rimsky5,
    rimsky6,
    rimsky7,
    rimsky8,
  ],
});

interface RimSkyProviderProps {
  children: React.ReactNode;
}

export const RimSkyProvider = ({ children }: RimSkyProviderProps) => {
  const [rimSkyImage] = useState([
    rimsky1,
    rimsky2,
    rimsky3,
    rimsky4,
    rimsky5,
    rimsky6,
    rimsky7,
    rimsky8,
  ]);

  return (
    <RimSkyContext.Provider
      value={{
        rimSkyImage,
      }}
    >
      {children}
    </RimSkyContext.Provider>
  );
};

export const useRimSky = (): RimSkyContextInterface => {
  return useContext(RimSkyContext);
};
