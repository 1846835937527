//Context / Use
import LR1 from "../assets/Pic/LEVEL RENDER/LR1.png";
import LR2 from "../assets/Pic/LEVEL RENDER/LR2.png";
import { createContext, useContext } from "react";

interface LevelRenderInterface {
  LevelRenderImage: string[];
}

const LevelRenderContext = createContext({
  LevelRenderImage: [LR1, LR2],
});

export const useLevelRender = (): LevelRenderInterface => {
  return useContext(LevelRenderContext);
};
