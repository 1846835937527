//CU

import { createContext, useContext } from "react";
import Zombie1 from "../assets/Pic/Zombie/zombie1.png";
import Zombie2 from "../assets/Pic/Zombie/zombie2.png";
import Zombie3 from "../assets/Pic/Zombie/zombie3.png";
import Zombie4 from "../assets/Pic/Zombie/zombie4.png";
import Zombie5 from "../assets/Pic/Zombie/zombie5.png";
import Zombie6 from "../assets/Pic/Zombie/zombie6.png";
import Zombie7 from "../assets/Pic/Zombie/zombie7.png";

interface ZombieContextInterface {
  ZombieImage: string[];
}

const ZombieContext = createContext<ZombieContextInterface>({
  ZombieImage: [Zombie1, Zombie2, Zombie3, Zombie4, Zombie5, Zombie6, Zombie7],
});

export const useZombie = (): ZombieContextInterface => {
  return useContext(ZombieContext);
};
