import { createContext, useContext } from "react";
import Necromancer1 from "../assets/Pic/The Necromancer/Necro1.png";
import Necromancer2 from "../assets/Pic/The Necromancer/Necro2.png";
import Necromancer3 from "../assets/Pic/The Necromancer/Necro3.png";
import Necromancer4 from "../assets/Pic/The Necromancer/Necro4.png";

//CPU

interface NecromancerContextInterface {
  NecromancerImage: string[];
}

const NecromancerContext = createContext({
  NecromancerImage: [Necromancer1, Necromancer2, Necromancer3, Necromancer4],
});

export const useNecromancer = (): NecromancerContextInterface => {
  return useContext(NecromancerContext);
};
