import React from "react";
import {Button, styled} from "@mui/material";

function LogIn() {

    const StyledH1 = styled("h1")({
        fontSize: "50px",
        margin: "0 auto",
        textAlign: "center",
        color: "white",
        fontFamily: "Apple Chancery, cursive",
        border: "silver 2px solid",
        borderRadius: "30px",
        boxShadow: "black 5px 5px 5px 5px",
        width: "80%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    });
    const StyledH2 = styled("h2")({
        fontSize: "30px",
        margin: "0 auto",
        textAlign: "center",
        color: "white",
        fontFamily: "Apple Chancery, cursive",
        textDecoration: "underline",
    });
    const StyledButton = styled(Button)({
        margin: "10px",
        color: "white",
        //make button see through
        boxShadow: "black 5px 5px 5px 1px",
        borderRadius: "50%",
        backgroundColor: "green",
        border: "silver 2px solid",
        opacity: "0.8",
        fontFamily: "Apple Chancery, cursive",
        "&:hover": {
            opacity: 0.5,
            backgroundColor: "green",
        },
    });
    const StyledBox = styled("div")({
        display: "block",
        margin: "0 auto",
        textAlign: "center",
        color: "white",
        fontFamily: "Apple Chancery, cursive",
        borderRadius: "10px",
        width: "60%",
        paddingBottom: "100px",
    });

    return (
        <>
            <StyledBox>
                <br/>
                <br/>
                <StyledH1>Welcome To the Login Page</StyledH1>
                <br/>
                <br/>
                <StyledH2>
                    If Your Not Suppose To be Here then You will not be able to login
                </StyledH2>
                <br/>
                <br/>
            </StyledBox>
        </>
    );
}

export default LogIn;
