import React, {ReactNode, useState} from "react";
import {Button, styled} from "@mui/material";
import {useRimSky} from "../StateManagment/RimSkyState";
import Divider from "@mui/material/Divider";
import {useFPSDungeon} from "../StateManagment/FPSDungeonState";
import {useNecromancer} from "../StateManagment/NecromancerState";
import {useGameOfLife} from "../StateManagment/GameOfLifeState";
import {useZombie} from "../StateManagment/ZombieState";
import {useLevelRender} from "../StateManagment/LevelRenderState";
import goRecruitImage from "../assets/GoRecruitSplashPageIcon.svg"
import goRecruitLogo from "../assets/GoRecruitLogo.svg"
//Styled Components
export const StyledBox = styled("div")({
  display: "block",
  margin: "0 auto",
  textAlign: "center",
  color: "white",
  fontFamily: "Apple Chancery, cursive",
  borderRadius: "10px",
  width: "60%",
});

export const StyledImg = styled("img")({
  maxWidth: "600px",
  maxHeight: "500px",
  minHeight: "300px",
  minWidth: "250px",
  width: "100%",
  height: "100%",
  border: "wheat 2px solid",
  borderRadius: "30px",
  margin: "20px auto",
  boxShadow: "black 5px 5px 5px 5px",
});

const StyledDivider = styled(Divider)({
  width: "98%",
  border: "wheat 2px solid",
  opacity: "0.5",
});

export const StyledH1 = styled("h1")({
  fontSize: "45px",
  margin: "0 auto",
  textAlign: "center",
  color: "white",
  fontFamily: "Apple Chancery, cursive",
  border: "silver 2px solid",
  borderRadius: "30px",
  boxShadow: "black 5px 5px 5px 5px",
  width: "80%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});
export const StyledH2 = styled("h2")({
  fontSize: "30px",
  margin: "0 auto",
  textAlign: "center",
  color: "white",
  fontFamily: "Apple Chancery, cursive",
  textDecoration: "underline",
});
type StyledButtonProps = {
  children: ReactNode;
  href: string;
  target: string;
};
export const StyledButton = styled(Button)({
  margin: "10px",
  color: "white",
  //make button see through
  boxShadow: "black 5px 5px 5px 1px",
  borderRadius: "50%",
  backgroundColor: "green",
  border: "silver 2px solid",
  opacity: "0.8",
  fontFamily: "Apple Chancery, cursive",
  "&:hover": {
    opacity: 0.5,
    backgroundColor: "green",
  },
});
const ArchiveGameWorks = () => {
  //Comes From StateManagment
  const {rimSkyImage} = useRimSky();
  const {FPSDungeonImage} = useFPSDungeon();
  const {NecromancerImage} = useNecromancer();
  const {GameOfLifeImage} = useGameOfLife();
  const {LevelRenderImage} = useLevelRender();

  const {ZombieImage} = useZombie();
  //Counter
  const [ rimCurrentPic, setRimCurrentPic ] = useState(0);
  const [ FPSDungeonCurrentPic, setFPSDungeonCurrentPic ] = useState(0);
  const [ NecromancerCurrentPic, setNecromancerCurrentPic ] = useState(0);
  const [ ZombieCurrentPic, setZombieCurrentPic ] = useState(0);
  const [ GameOfLifeCurrentPic, setGameOfLifeCurrentPic ] = useState(0);
  const [ LevelRenderCurrentPic, setLevelRenderCurrentPic ] = useState(0);

  return (
    <StyledBox>
      <br/>
      <br/>
      <div>
        <StyledH1 data-testid="RimSky Header" style={ {textAlign: "center"} }>
          GoRecruit
        </StyledH1>
        <StyledH2>
          Application to help the army in recruitering by provideding information on the Go.
        </StyledH2>
      </div>
      <li>React</li>
      <li>Spring Boot</li>
      <li>Pipeline</li>
      <li>Docker</li>
      <li>EMSA Authentication</li>
      <li>Postgres</li>
      <br/>
      <img src={goRecruitImage} alt={ goRecruitImage }/>
      <img src={goRecruitLogo} alt={ goRecruitLogo }/>
      <br/>
      <br/>
      <div>
        <StyledH1 data-testid="RimSky Header" style={ {textAlign: "center"} }>
          RimSky
        </StyledH1>
        <StyledH2>
          RimSky or Skyrim? This was a midterm Capstone Project at FSU.
        </StyledH2>
      </div>
      <li>AI</li>
      <li>Animations</li>
      <li>Sound Design</li>
      <li>Level Design</li>
      <li>Boss Fights</li>
      <li>Sword & Shield Fighting</li>
      <li>Archer Enemies & Sword Enemies</li>
      <StyledImg
        src={ rimSkyImage[rimCurrentPic] }
        alt={ "RimSky Image" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (rimCurrentPic > 0) {
            setRimCurrentPic(rimCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (rimCurrentPic !== rimSkyImage.length - 1) {
            setRimCurrentPic(rimCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <div>
        <StyledDivider/>
        <br/>
        <StyledDivider/>
        <br/>
        <StyledH1 style={ {textAlign: "center"} }>RimSky Trailer</StyledH1>
        <br/>
        <iframe
          width="100%"
          height="500px"
          src="https://www.youtube.com/embed/UzITkomPnuE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div>
        <br/>
        <br/>
        <StyledDivider/>
        <br/>
        <StyledDivider/>
        <br/>
        <br/>
        <StyledH1
          data-testid="FPS Dungeon Header"
          style={ {textAlign: "center"} }
        >
          FPS Dungeon
        </StyledH1>
        <br/>
      </div>
      <StyledH2>
        CapStone Project At FSU FPS With Tower Defense Twist and multiple Levels
      </StyledH2>
      <li>AI</li>
      <li>Animations</li>
      <li>Sound Design</li>
      <li>Level Design</li>
      <li>Enemy Spawning</li>
      <li>Multiple Enemy Types & Unique Enemy Logic</li>
      <StyledImg
        src={ FPSDungeonImage[FPSDungeonCurrentPic] }
        alt={ "FPSDungeon Image" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (FPSDungeonCurrentPic > 0) {
            setFPSDungeonCurrentPic(FPSDungeonCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (FPSDungeonCurrentPic !== FPSDungeonImage.length - 1) {
            setFPSDungeonCurrentPic(FPSDungeonCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1 style={ {textAlign: "center"} }>FPS Dungeon Trailer</StyledH1>
      <br/>
      <iframe
        width="100%"
        height="500px"
        src="https://www.youtube.com/embed/FhfZbEWMzG4"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1 data-testid="TheNecromancerHeader">The Necromancer</StyledH1>
      <br/>
      <StyledH2>
        Prototype Build where you controlled the army of the dead
      </StyledH2>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <StyledImg
        src={ NecromancerImage[NecromancerCurrentPic] }
        alt={ "Necromancer Images" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (NecromancerCurrentPic > 0) {
            setNecromancerCurrentPic(NecromancerCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (NecromancerCurrentPic !== NecromancerImage.length - 1) {
            setNecromancerCurrentPic(NecromancerCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1 data-testid="gameOfLifeHeader">Game Of Life</StyledH1>
      <br/>
      <StyledH2>Based Off Jon Conway's Game Of Life</StyledH2>
      <StyledImg
        src={ GameOfLifeImage[GameOfLifeCurrentPic] }
        alt={ "Game Of Life Images" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (GameOfLifeCurrentPic > 0) {
            setGameOfLifeCurrentPic(GameOfLifeCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (GameOfLifeCurrentPic !== GameOfLifeImage.length - 1) {
            setGameOfLifeCurrentPic(GameOfLifeCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1 data-testid="zombieHeader">Zombie</StyledH1>
      <br/>
      <StyledH2>My First Zombie FPS Game I Created</StyledH2>
      <StyledImg
        src={ ZombieImage[ZombieCurrentPic] }
        alt={ "Zombie Images" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (ZombieCurrentPic > 0) {
            setZombieCurrentPic(ZombieCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (ZombieCurrentPic !== ZombieImage.length - 1) {
            setZombieCurrentPic(ZombieCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1>Level Render</StyledH1>
      <br/>
      <StyledH2>Level Render Engine Developed Using DirectX & Vulcan</StyledH2>
      <StyledImg
        src={ LevelRenderImage[LevelRenderCurrentPic] }
        alt={ "Level Render Images" }
      ></StyledImg>
      <StyledButton
        onClick={ () => {
          if (LevelRenderCurrentPic > 0) {
            setLevelRenderCurrentPic(LevelRenderCurrentPic - 1);
          }
        } }
      >
        Back
      </StyledButton>
      <StyledButton
        onClick={ () => {
          if (LevelRenderCurrentPic !== LevelRenderImage.length - 1) {
            setLevelRenderCurrentPic(LevelRenderCurrentPic + 1);
          }
        } }
      >
        Next
      </StyledButton>
      <br/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledDivider/>
      <br/>
      <StyledH1 style={ {textAlign: "center"} }>Level Render Videos</StyledH1>
      <br/>
      <iframe
        width="100%"
        height="500px"
        src="https://www.youtube.com/embed/ktpo1reG7Zo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br/>
      <br/>
      <iframe
        width="100%"
        height="500px"
        src="https://www.youtube.com/embed/XgE5-eCm0n0"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <br/>
      <br/>
      <iframe
        width="100%"
        height="500px"
        src="https://www.youtube.com/embed/xlX-l5W6njs"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </StyledBox>
  );
};

export default ArchiveGameWorks;
