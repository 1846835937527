import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link, Route, useNavigate} from 'react-router-dom';
import headerImage from '../assets/gold.jpeg';
import ParticlesComponent from "./ParticlesComponent";

interface page {
  name: string;
  path: string;
}


const pages: page[] = [ {name: 'Home', path: "/",},
  {name: 'About Me', path: "/AboutMe",},
  {name: 'Archive Work', path: "/ArchiveGameWork"},
  {name: 'Web Games', path: "/WebGames"},

];
const settings = [ 'Profile', 'Account', 'Dashboard', 'Logout' ];

function ResponsiveAppBar() {

  const [ anchorElNav, setAnchorElNav ] = React.useState<null | HTMLElement>(null);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <>
    <AppBar sx={ {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${ headerImage })`, // Image background
        opacity: 0.8,
      }
    }// Adjust the opacity for transparency
    } position="static">
      <Container maxWidth="2xl">
        <Toolbar disableGutters>


          <Box sx={ {flexGrow: 1, display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'}} }>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={ handleOpenNavMenu }
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={ anchorElNav }
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'left',
              } }
              keepMounted
              transformOrigin={ {
                vertical: 'top',
                horizontal: 'left',
              } }
              open={ Boolean(anchorElNav) }
              onClose={ handleCloseNavMenu }
              sx={ {
                display: {xs: 'block', md: 'none'},
              } }
            >
              { pages.map((page) => (
                <MenuItem key={ page.path } onClick={ handleCloseNavMenu }>
                  <Link to={ page.path } style={ {textDecoration: 'auto', color: 'black'} }>
                    <Typography textAlign="center">{ page.name }</Typography>
                  </Link>
                </MenuItem>
              )) }
            </Menu>
          </Box>
          {/*MOBILE NAVBAR*/
          }

          <Box sx={ {marginLeft: "auto", display: {xs: "none", sm: 'flex', md: 'flex', lg: 'flex'}} }>
            {
              pages.map((page) => (
                <Button
                  key={ page.name }
                  onClick={ handleCloseNavMenu }
                  sx={ {my: 2, display: 'block', fontSize: "1.2rem"} }
                >
                  <Link to={ page.path } style={ {textDecoration: 'none', color: 'white'} }>
                    { page.name }
                  </Link>
                </Button>
              ))
            }
          </Box>


        </Toolbar>
      </Container>
    </AppBar>

  <ParticlesComponent/>
  </>
  )
    ;
}

export default ResponsiveAppBar;