import React from "react";



function PageNotFound()
{
    return (
    <div>
        <h1 style={{textAlign: "center", textDecoration: "underline", color: "red", backgroundColor: "black"}}>404 Page Not Found</h1>
    </div>
    )
}

export default PageNotFound