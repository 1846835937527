import React from "react";
import Divider from "@mui/material/Divider";
import "../StyleSheets/AboutMe.css";
import swf from "../assets/SWF.svg";
import otc from "../assets/otclogo.gif";
import fiveOne from "../assets/47923.png.webp";
import associates from "../assets/Associates.png";
import swfcoc from "../assets/SWFCOC.png";
import springBoot from "../assets/springBoot.png";
import unity from "../assets/unity.png";
import directx from "../assets/directx.png";
import react from "../assets/react.png";
import auth from "../assets/auth0.png";
import postgresql from "../assets/postgresql.png";
import amu from "../assets/AMU.jpg";
import fsu from "../assets/Pic/fullsailLogo.png";
import amuBach from "../assets/AMUBach.jpeg";
import FSUBach from "../assets/FSUdeplomia.png";

function AboutMe() {
  return (
    <div>
      <br />
      <br />
      <br />
      <div className={"topDiv"}>
        <h1
          className={"aboutMeH1"}
          style={{ textAlign: "center", color: "white" }}
        >
          About Me
        </h1>
        <p
          className={"aboutMe"}
          style={{
            fontSize: 30,
            textAlign: "center",
            color: "white",
            textDecoration: "underline",
          }}
        >
          Hard-working college graduate with Information Technology in Mobile
          Development (3.9 GPA) with 15+ years of work experience. Aiming to
          leverage academic experience and proven knowledge of application
          development, programming, and troubleshooting to successfully fill the
          Game Development role at your company. Frequently praised as adaptable
          by my peers, I can be relied upon to help your company achieve its
          goals.
        </p>
        <Divider style={{ padding: 2, backgroundColor: "yellow" }} />
      </div>
      <div className={"middleDiv"}>
        <br />
        <h1
          className={"aboutMeH1"}
          style={{ textAlign: "center", color: "white" }}
        >
          Experience
        </h1>
        <img src={swf} alt={"swfImageOne"} />
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          July 2022 – CURRENT Army Future Command Software Developer Support
          Army in innovating and prototype soultions Conducted Tech Accelerator
          Course Minimum oversight and fast learning environment.
        </p>
        <img src={otc} alt={"otcImage"} />
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          FEB 2019 - JULY 2022 (3 YEARS 5 MONTHS) MCTD NCO TESTER, OPERATION
          TEST COMMAND Support OTC in testing of military's next technology.
          Support TDY movements and test officers. Minimum oversight and fast
          learning environment.
        </p>
        <img
          style={{ width: "10%", height: "10%" }}
          src={fiveOne}
          alt={"fiveOneImage"}
        />
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          MARCH 2016 – MARCH 2019 (3 YEARS) SECTION CHIEF, 3-501ST AVATION
          Managed team of 12 soldiers trained in executing's daily mission
          requirements in fast op tempo environment. Support the battalion with
          all S6 needs from IT Management and helpdesk support, Radios,
          Satellite
        </p>

        <Divider style={{ padding: 2, backgroundColor: "yellow" }} />
      </div>
      <div className={"bottomDiv"}>
        <br />
        <h1
          className={"aboutMeH1"}
          style={{ textAlign: "center", color: "white" }}
        >
          Education
        </h1>
        <div className={"amuimg"}>
          <img
            style={{ width: "30%", height: "30%" }}
            src={swfcoc}
            alt={"cocImage"}
          />
          <img src={swf} alt={"swfImageTwo"} />
        </div>
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          DECEMBER 2022 GALVANIZE TECH-ACCELERATOR SOFTWARE DEVELOPMENT, FULL
          STACK DEVELOPMENT
        </p>
        <div className={"amuimg"}>
          <img
            style={{ width: "30%", height: "30%" }}
            src={FSUBach}
            alt={"fsuBachImage"}
          />
          <img
            style={{ width: "20%", height: "20%" }}
            src={fsu}
            alt={"fsuImage"}
          />
        </div>
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          DECEMBER 2022 GRADUATE BACHELOR OF GAME DEVELOPMENT, FSU
        </p>
        <div className={"amuimg"}>
          <img
            style={{ width: "30%", height: "30%" }}
            src={amuBach}
            alt={"amuBachImage"}
          />
          <img
            style={{ width: "20%", height: "20%" }}
            src={amu}
            alt={"amuImage"}
          />
        </div>
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          {" "}
          AUGUST 2021 BACHELOR OF INFORMATION TECHNOLOGY MOBILE DEVELOPMENT, AMU
          GPA 3.9 Summa Cum Laude
        </p>
        <div className={"amuimg"}>
          <img
            style={{ width: "30%", height: "30%" }}
            src={associates}
            alt={"associatesImage"}
          />
          <img style={{ width: "20%", height: "20%" }} src={amu} alt={""} />
        </div>
        <p
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          {" "}
          FEBRUARY 2018 ASSOCIATES GENERAL STUDIES, AMU
        </p>
        <Divider style={{ padding: 2, backgroundColor: "yellow" }} />
        <h1 style={{ textAlign: "center", color: "white" }}>
          Extra Subjects of Knowledge
        </h1>
        <div className={"icons"}>
          <img src={springBoot} alt={"springBootImage"} />
          <img
            src={unity}
            alt={"unityImage"}
            style={{ backgroundColor: "white", borderRadius: "20%" }}
          />
          <img src={directx} alt={"directXImage"} />
          <img src={react} alt={"reactImage"} />
          <img src={auth} alt={"authImage"} />
          <img src={postgresql} alt={"postgresImage"} />
        </div>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Unity Game Development
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          C#, C++, Java
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Data Algorithm
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          DirectX, Vulcan, Opengl
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Spring Boot
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          React
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Full Stack Development
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Auth0
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Postgresql
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Redux
        </li>
        <br />
        <li
          className={"aboutMe"}
          style={{
            textDecoration: "underline",
            fontSize: 30,
            textAlign: "center",
            color: "white",
          }}
        >
          Comptia Passed A+, Net+, Sec+
        </li>
        <br />
        <Divider style={{ padding: 2, backgroundColor: "yellow" }} />
      </div>
    </div>
  );
}

export default AboutMe;
