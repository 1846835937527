import React from "react";
import NavBar from "./Component/NavBar";
import { Route, Routes } from "react-router-dom";
import FooterBar from "./Component/FooterBar";
import HomePage from "./routes/HomePage";
import AboutMe from "./routes/AboutMe";
import ArchiveGameWork from "./routes/ArchiveGameWorks";
import PageNotFound from "./routes/PageNotFound";
import LogIn from "./routes/LogIn";
import WebGames from "./routes/WebGames";
import ParticlesComponent from "./Component/ParticlesComponent";
import Box from "@mui/material/Box";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        {/*Webpage Routes Go HERE PLEASE !!!*/}
        <Route path="/" element={<HomePage />} />
        <Route path="/AboutMe" element={<AboutMe />} />
        <Route path={"/ArchiveGameWork"} element={<ArchiveGameWork />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="LogIn" element={<LogIn />} />
        <Route path="WebGames" element={<WebGames />} />
      </Routes>
      <FooterBar />
    </div>
  );
}

export default App;
